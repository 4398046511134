import { ReactElement, useCallback } from 'react';
import { Typography, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useFormik, FormikHelpers } from 'formik';
import { useToggle } from 'react-use';

import { useAppSelector, useLazyRequest } from '~hooks/index';
import { selectCompanyState } from '~store/slices/company';
import { selectOrderState } from '~store/slices/order';
import { createSurvery } from '~services/survey';
import { SurveyCreateBody } from '~services/survey/types';
import { DescriptionWithIconBox, Rating } from '~components/index';
import { toAbsoluteUrl } from '~utils/assets';
import { getRatingOptions } from '~utils/survey';

import { SurveyFormProps } from './types';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import {
  SurveyFormContainer,
  SurveyFormInfoContainer,
  SurveyFormInfoContentInfo,
} from './styles';

const SurveyForm = ({ orderId }: SurveyFormProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: currenCompany } = useAppSelector(selectCompanyState);
  const { data: currentOrder } = useAppSelector(selectOrderState);

  const [isCompletedSurvey, toggleIsCompletedSurvey] = useToggle(false);

  const [, loadingCreateSurvey, , executeCreateSurvey] = useLazyRequest({
    request: createSurvery,
    withPostSuccess: () => {
      toggleIsCompletedSurvey(true);
      enqueueSnackbar('Encuesta enviada correctamente', {
        variant: 'success',
      });
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      toggleIsCompletedSurvey(false);

      const dataToSend: SurveyCreateBody = {
        routeItemId: orderId,
        question1: Number(values[FIELDS_NAME.DELAY_IN_DELIVERIES]),
        question2: Number(values[FIELDS_NAME.PACKAGING_QUALITY]),
        question3: Number(values[FIELDS_NAME.ATTENTION_RECEIVED]),
        notes: values[FIELDS_NAME.SUGGESTIONS],
      };

      await executeCreateSurvey(dataToSend);

      setSubmitting(false);
    },
    [toggleIsCompletedSurvey, orderId, executeCreateSurvey],
  );

  const { handleSubmit, getFieldProps, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (isCompletedSurvey)
    return (
      <Typography variant="h5" fontWeight={500}>
        Muchas gracias por ayudarnos a mejorar
      </Typography>
    );

  return (
    <SurveyFormContainer>
      <SurveyFormInfoContainer>
        <Typography variant="h5" fontWeight={500}>
          {`Califica tu experiencia con ${currenCompany?.companyName}`}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Nos interesa tu opinión, por eso te pedimos que tomes unos segundos
          para calificar la experiencia que tuviste con nuestro servicio.
        </Typography>

        {(currentOrder?.driverName || currentOrder?.store) && (
          <SurveyFormInfoContentInfo>
            {currentOrder?.driverName && (
              <DescriptionWithIconBox
                iconUrl={toAbsoluteUrl('/images/user.svg')}
                label="Chofer"
                description={currentOrder?.driverName ?? ''}
              />
            )}

            {currentOrder?.store && (
              <DescriptionWithIconBox
                iconUrl={toAbsoluteUrl('/images/store.svg')}
                label="Tienda"
                description={currentOrder.store}
              />
            )}
          </SurveyFormInfoContentInfo>
        )}
      </SurveyFormInfoContainer>

      <Stack
        spacing={3}
        component="form"
        onSubmit={handleSubmit}
        alignItems="center"
      >
        <Rating
          label="¿Cómo calificarías el tiempo de demora en el envío/retiro de tu producto?"
          options={getRatingOptions(false)}
          {...getFieldProps(FIELDS_NAME.DELAY_IN_DELIVERIES)}
        />

        <Rating
          label="Respecto al estado del paquete, ¿cómo calificarías la calidad del embalaje?"
          options={getRatingOptions(false)}
          {...getFieldProps(FIELDS_NAME.PACKAGING_QUALITY)}
        />

        <Rating
          label="¿Cómo calificarías la atención brindada por el chofer?"
          options={getRatingOptions(true)}
          {...getFieldProps(FIELDS_NAME.ATTENTION_RECEIVED)}
        />

        <TextField
          fullWidth
          size="medium"
          label="¿Tienes alguna sugerencia que desees compartir con nosotros?"
          autoComplete="off"
          multiline
          rows={5}
          InputLabelProps={{ shrink: true }}
          {...getFieldProps(FIELDS_NAME.SUGGESTIONS)}
        />

        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          sx={{ maxWidth: 300, width: '100%' }}
          loading={loadingCreateSurvey || isSubmitting}
        >
          Finalizar
        </LoadingButton>
      </Stack>
    </SurveyFormContainer>
  );
};

export default SurveyForm;
