import { styled, Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const labelArea = 'order-info-label-area';
const descriptionArea = 'order-info-description-area';
const stepperArea = 'order-info-stepper-area';
const stateArea = 'order-info-state-area';
const infoArea = 'order-info-info-area';

export const classes = {
  infoDriverDescription: 'Order-info-driver-description',
};

export const OrderInfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: 496,
  position: 'relative',
  gridAutoRows: 'max-content',
}));

export const OrderInfoTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,

  [theme.breakpoints.up('md')]: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const OrderInfoText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightMedium,

  [theme.breakpoints.up('md')]: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const OrderInfoContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  gridAutoRows: 'max-content',
  gridTemplateAreas: `
    "${labelArea}"
    "${descriptionArea}"
    "${stepperArea}"
    "${stateArea}"
    "${infoArea}"
  `,

  [theme.breakpoints.up('md')]: {
    gridTemplateAreas: `
      "${labelArea}"
      "${infoArea}"
      "${stepperArea}"
      "${stateArea}"
      "${descriptionArea}"
    `,
  },
}));

export const OrderInfoContentLabel = styled(Box)(({ theme }) => ({
  gridArea: labelArea,
  display: 'grid',
  gap: theme.spacing(1),
  justifyItems: 'center',
}));

export const OrderInfoContentLabelTrackingCodeTypography = styled(
  OrderInfoText,
)(({ theme }) => ({
  '& :first-of-type': {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    '& :first-of-type': {
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
    },
  },
}));

export const OrderInfoContentDescription = styled(Box)(() => ({
  gridArea: descriptionArea,
}));

export const OrderInfoContentStepper = styled(Box)(({ theme }) => ({
  gridArea: stepperArea,
  margin: theme.spacing(2, 0),
}));

export const OrderInfoContentState = styled(Box)(({ theme }) => ({
  gridArea: stateArea,
  display: 'grid',
  gap: theme.spacing(0.5),

  '& :first-of-type': {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    '& :first-of-type': {
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
    },
  },
}));

export const OrderInfoContentInfo = styled(Box)(({ theme }) => ({
  gridArea: infoArea,
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: theme.spacing(2, 0),
  marginTop: theme.spacing(1),

  [`& .${classes.infoDriverDescription}`]: {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',

    [`& .${classes.infoDriverDescription}`]: {
      display: 'grid',
    },
  },
}));

export const OrderInfoActions = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  justifyItems: 'center',
}));

export const OrderInfoActionsButton = styled(Button)({
  maxWidth: 300,
  width: '100%',
});

export const OrderInfoActionsButtonLoading = styled(LoadingButton)({
  maxWidth: 300,
  width: '100%',
});
